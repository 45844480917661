import React from "react";
import pen from "../../assests/images/pen.png"

export default function BlogsImg(props) {
  const theme = props.theme;
  return (
    <img
    height="70%"
    width="70%"
    src={pen}
    />
  );
}
