import React from "react";
import project from "../../assests/images/software.png"

export default function ProjectsImg(props) {
  const theme = props.theme;
  return (
    <img
      src={project}
    />
  );
}
