import React from "react";
import lekanimage from "../../assests/images/Lekan-image.png"

export default function FullStackImg(props) {
  const theme = props.theme;
  return (

  <img 
    id="ade8c9af-7e2e-4eda-b5c8-b06129257226"
    width="80%"
    height="80%"
    viewBox="0 0 1076.06371 755.2279"
    src={lekanimage} 
  />

  );
}
