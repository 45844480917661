import React from "react";
import cloudimage from "../../assests/images/technology.png"

export default function CloudInfraImg(props) {
  const theme = props.theme;
  return (
    <img 
    id="ade8c9af-7e2e-4eda-b5c8-b06129257226"
    width="90%"
    height="90%"
    viewBox="0 0 1076.06371 755.2279"
    src={cloudimage} 
  />
  );
}
