/* Change this file to get your personal Porfolio */
// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
};

//Home Page
const greeting = {
  title: "Welcome!",
  title2: "Isaac",
  logo_name: "Lakeside",
  nickname: "Lakeside",
  full_name: "Olalekan Isaac Ogunremi..",
  subTitle: "a lifelong learner & DevOps Cloud Enthusiast.",
  resumeLink:
    "https://drive.google.com/file/d/1COgEYjV75xnrTF09syKa4rFJpebekzoa/view?usp=sharing",
  mail: "mailto:OOOgunremi@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/OOgunremi",
  gitlab: "https://gitlab.com/OOgunremi",
  linkedin: "https://www.linkedin.com/in/isaacogunremi/",
  gmail: "OOOgunremi@gmail.com",
  twitter: "https://twitter.com/LekanOgunremi",
  instagram: "https://www.instagram.com/lekanogunremi/",
};

const skills = {
  data: [
    {
      title: "DevOps & Developer",
      fileName: "FullStackImg",
      skills: [
        "⚡ Collaborate with the software and operations teams",
        "⚡ Implement CICD pipeline workflow accordingly",
        "⚡ Build responsive website using ReactJS",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Provide expert opinion on AWS services",
        "⚡ Migrate IT Infrastructure from OnPrem to Cloud",
        "⚡ Provide deep insight into cloud security vulnerability",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "simple-icons:jenkins",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "MSSQL",
          fontAwesomeClassname: "simple-icons:microsoftsqlserver",
          style: {
            color: "#CC2927",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "NGINX",
          fontAwesomeClassname: "simple-icons:nginx",
          style: {
            color: "#009639",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "Tomcat",
          fontAwesomeClassname: "simple-icons:apachetomcat",
          style: {
            color: "#F8DC75",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Lighthouse Labs, Canada",
      subtitle: "Diploma in Web Development",
      logo_path: "ssec.png",
      alt_name: "LHL",
      duration: "2022",
      descriptions: [
        "⚡ Courses: JavaScript, Nodejs, Ruby on Rails, SQL, ReactJS, etc",
      ],
      website_link: "https://www.lighthouselabs.ca/",
    },
    {
      title: "University of Strathclyde, UK",
      subtitle: "Master of Business Administration",
      logo_path: "ssec.png",
      alt_name: "Strath",
      duration: "2016 - 2018",
      descriptions: [
        "⚡ Graduated with a distiction 3.87/4.00.",
      ],
      website_link: "https://www.strath.ac.uk/business/",
    },
    {
      title: "University of Ilorin, Nigeria",
      subtitle: "Bachelor of Electrical Engineering",
      logo_path: "ssec.png",
      alt_name: "Unilorin",
      duration: "2000 - 2003",
      descriptions: [
        "⚡ Graduated with a CGPA of 3.47/4.00.",
      ],
      website_link: "https://uilugportal.unilorin.edu.ng/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "AWS",
      subtitle: "AWS Solutions Architect Associate (in view)",
      logo_path: "AWS.png",
      certificate_link:
        "https://drive.google.com/file/d/1cBAep4Bldv5gjXXsJ4C1-EUYx8cljAcu/view?usp=share_link",
      alt_name: "AWS Solutions Architect Associate (in view)",
      // color_code: "#2AAFED",
      color_code: "#808080",
    },
    {
      title: "PSM1",
      subtitle: "Professional Scrum Master 1",
      logo_path: "scrum.svg",
      certificate_link:
        "https://drive.google.com/file/d/1CQ2hYk-jhWUbsh13Lr2wjfVl7ujh5Bdt/view?usp=share_link",
      alt_name: "Scrum.Org",
      color_code: "#89CFF0",
    },
    {
      title: "GPMP",
      subtitle: "Google Poject Manager Professional",
      logo_path: "Google.png",
      certificate_link:
        "https://drive.google.com/file/d/1xoVkXthKlWb78mXzEpRqRBU3-5y-SSAq/view?usp=share_link",
      alt_name: "Google",
      // color_code: "#F6B808",
      color_code: "#808080",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "DevOps Cloud Engineer",
  description:
    "I coordinate and create solutions for Software teams by providing end-to-end automation of software development process. I ensure that client's objectives are met in due time and quality applications are released to the market in a timely fashion. I use AWS Cloud primarily. Occasionally, I write codes. I build highly responsive web applications.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Experience",
      experiences: [
        {
          title: "DevOps Engineer",
          company: "ESG Solutions",
          company_url: "https://www.esgsolutions.com/",
          logo_path: "ESG.svg",
          duration: "Dec 2022 - Date",
          location: "Remote, Canada",
          description: `Skills: Cloud Storage · Java · Jira · File Systems · FSx · Continuous Integration and Continuous Delivery (CI/CD) · Eclipse · Microsoft SQL Server · Active Directory · Migration Projects · MongoDB · React.js · GitHub · Docker · Virtual Private Network (VPN) · Linux · Jenkins · Amazon Web Services (AWS) · DevOps.`,
          color: "#0071C5",
        },
        {
          title: "IT Project Manager",
          company: "Monitor Healthcare Ltd",
          company_url: "https://monitorhealthcare.com/",
          logo_path: "MHL.jpeg",
          duration: "2021 - 2022",
          location: "Remote, Canada",
          description: `Served as the Product Owner in the development and deployment of healthcare applications. Approved user experiences UI/UX designs. Collaborated cross-functionally with stakeholders and the Scrum Team to ensure the product's development aligns with strategic business objectives.
          • Launched company core applications within 6 months time frame as planned.
          • Set up IT infrastructure for the company on AWS. Served as the Product Owner in the development and deployment of healthcare applications. 
          • Collaborated cross-functionally with stakeholders and the Scrum Team to ensure the product's development aligns with strategic business objectives. 
          • Launched company core applications within 6 months time frame as planned. 
          • Set up Information Technology infrastructure for the company on AWS.`,
          color: "#0071C5",
        },
        {
          title: "DevOps Cloud Engineer",
          company: "SLB",
          company_url: "https://www.slb.com/",
          logo_path: "schlumberger.png",
          duration: "2017 - 2020",
          location: "Global",
          description: `Collaborated with the software and operations teams to understand requirements and
          objectives, and planned the CICD pipeline workflow accordingly.
          • Set up Jenkins and Github Actions CI/CD pipelines – with plugins, credentials, and declarative scripts for
          continuous build, code analysis, application image build with Docker, and deployment for
          automated testing and delivery of the application.
          • Used Terraform script (Infrastructure as code) for infrastructure provisioning and configuration
          of the resources – VPC, EC2, S3, IAM, ELB, Kubernetes clusters, Nexus, SonarQube, Docker
          that will be used for the CI/CD pipeline and also for the testing and production environment.
          • Set up AWS S3 as external storage.Set up the deployment of resources for applications in Kubernetes or EC2 Server.
          • Ensured continuous monitoring using Prometheus and Grafana to monitor key metric points
          such as CPU usage, error rate, traffic, and latency.
          Other Experience
          • Optimized cost through reserved instances, selection, and changing of EC2 instance types
          based on resource need, S3 storage classes, and S3 lifecycle policies, leveraging autoscaling.
          • Designed, built, managed and operated cloud infrastructure and configured environment with
          a focus on automation and infrastructure as code.
          • Participated actively in the daily stand-ups, team meetings and brainstorming sessions.`,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteering",
      experiences: [
        {
          title: "Volunteer Team Member",
          company: "Monitor Health Ltd",
          company_url: "https://monitorhealthcare.com/",
          logo_path: "MHL.jpeg",
          duration: "2021",
          location: "Nigeria",
          description:
            "Various breast cancer and malaria awareness programs",
          color: "#FBBD18",
        },
        {
          title: "Audit Team Member",
          company: "NYSC Polio Eradication Program",
          company_url: "https://www.nysc.gov.ng/",
          logo_path: "nysc.svg",
          duration: "2005",
          location: "Nigeria",
          description:
            "Monitoring the quality of vaccine administration",
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects' tech stack includes: NodeJS, React, NextJS, Stripe, Auth0, Jquery, Tomcat, Jenkins, GitHub Actions, NGINX, Sanity.io, etc.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "lakeside.png",
    description:
      "You can reach me at: ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I hardly blog unless it is absolutely necessary. If so, it will be on my twitter page.",
    link: "https://twitter.com/LekanOgunremi",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Cellmate",
      url: "https://github.com/OOgunremi/cellmate",
      description:
        "This is a Next.js project bootstrapped with create-next-app.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
        {
          name: "Stripe",
          iconifyClass: "logos-stripe",
        },
        {
          name: "ReactJs",
          iconifyClass: "logos-react",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/OOgunremi/cellmate",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
    {
      id: "1",
      name: "SummertimeMart",
      url: "https://github.com/OOgunremi/SummertimeMart/tree/main/summertimemart",
      description:
        "An app for small e-commerce business.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
        {
          name: "NodeJs",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "ReactJs",
          iconifyClass: "logos-react",
        },
        {
          name: "Auth0",
          iconifyClass: "logos-auth0",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/OOgunremi/SummertimeMart/tree/main/summertimemart",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
    {
      id: "2",
      name: "TinyApp",
      url: "https://github.com/OOgunremi/tinyapp",
      description:
        "TinyApp is a full stack web application shortens long URLs, developed using Node and Express.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/OOgunremi/tinyapp",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
