import React from "react";
import isaac from "../../assests/images/lekan.png"

export default function ExperienceImg(props) {
  const theme = props.theme;
  return (
    <img 
    id="ade8c9af-7e2e-4eda-b5c8-b06129257226"
    alt="Experience"
    width="80%"
    height="80%"
    viewBox="0 0 1076.06371 755.2279"
    src={isaac}
  />
  );
}
